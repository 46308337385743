import React, { ReactElement, useEffect } from 'react';
import BNUABI from '../../contracts/BNU_ABI.json';
import STAKEABI from '../../contracts/AvatarArtStaking_ABI.json';

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { clearData } from '@containers/Home/store/reducers';

import { setAddressUser, setEnvContract } from '@containers/App/store/reducers';
import { selectAppStore } from '@containers/App/store/selecters';

import { useDispatch, useSelector } from 'react-redux';
interface Props {
  children: JSX.Element[] | JSX.Element;
}

declare const window: any;

export function Web3Provider({ children }: Props): ReactElement {
  const dispatch = useDispatch();
  const { clearCache } = useSelector(selectAppStore);

  useEffect(() => {
    window.addEventListener("load", async () => {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1: process.env.REACT_APP_RPC,
              [process.env.REACT_APP_NETID || '']: process.env.REACT_APP_RPC,
            },
            network: 'binance',
            chainId: process.env.REACT_APP_NETID,
            bridge: "https://pancakeswap.bridge.walletconnect.org"
          }
        }
      };
      const web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions
      })
      // if not logined
      if (!web3Modal.cachedProvider) {
        const provider = new Web3.providers.HttpProvider(process.env.REACT_APP_RPC || '');
        const web3 = new Web3(provider);
        setRedux(web3);
        return;
      };
      // else load data from logined
      const clearCache: any = () => web3Modal.clearCachedProvider();
      let web3: any = null;
      try {
        const provider = await web3Modal.connect();
        provider.on("disconnect", () => resetData());
        web3 = new Web3(provider);
      } catch (error) {
        const provider = new Web3.providers.HttpProvider(process.env.REACT_APP_RPC || '');
        web3 = new Web3(provider);
      }
      setRedux(web3, clearCache);
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', async function () {
          const accounts = await web3.eth.getAccounts();
          accounts.length && dispatch(setAddressUser(accounts[0]));
        });
        window.ethereum.on('chainChanged', async (netId: number) => {
          if (netId.toString() !== process.env.REACT_APP_NETID) switchNetWork();
          setRedux(web3);
        })
      }
      if (web3.eth) {
        const netId = await web3.eth.net.getId();
        if (netId.toString() !== process.env.REACT_APP_NETID) switchNetWork();
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetData = () => {
    clearCache && clearCache()
    const provider = new Web3.providers.HttpProvider(process.env.REACT_APP_RPC || '');
    const web3 = new Web3(provider);
    setRedux(web3);
    dispatch(clearData());
    localStorage.clear();
  }
  const setRedux = async (web3: any, clearCache = null) => {
    const accounts = await web3.eth.getAccounts();
    const userAcc = accounts.length ? accounts[0] : null;
    dispatch(setAddressUser(userAcc))
    const ctToken = new web3.eth.Contract(
      BNUABI,
      process.env.REACT_APP_ADDRESS_CONTRACT_BNU,
    );
    const ctAvt = new web3.eth.Contract(
      STAKEABI,
      process.env.REACT_APP_ADDRESS_CONTRACT_STAKING,
    );
    dispatch(
      setEnvContract({
        web3: web3,
        contractBNU: ctToken,
        contractStake: ctAvt,
        clearCache
      }),
    );
  }

  const switchNetWork = async () => {
    await window?.ethereum?.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${parseInt(process.env.REACT_APP_NETID || '').toString(16)}`,
          chainName: process.env.REACT_APP_NAME_NET,
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
          },
          rpcUrls: [process.env.REACT_APP_RPC],
          blockExplorerUrls: ['https://bscscan.com']
        }
      ]
    })
  }
  return <div>{children}</div>;
}
