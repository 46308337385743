import React, { PropsWithChildren } from 'react';
import Footer from './Footer';
import Header from './Header';
import { SLayout } from './styles';

interface Props extends PropsWithChildren<any> {}

export default function Layout({ children }: Props) {
  return (
    <SLayout>
      <Header />
      {children}
      <Footer />
    </SLayout>
  );
}
