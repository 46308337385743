const theme = {
  fonts: {
    'Montserrat-BlackItalic': 'Montserrat-BlackItalic',
    'Montserrat-Black': 'Montserrat-Black',
    'Montserrat-BoldItalic': 'Montserrat-BoldItalic',
    'Montserrat-Bold': 'Montserrat-Bold',
    'Montserrat-ExtraBoldItalic': 'Montserrat-ExtraBoldItalic',
    'Montserrat-ExtraBold': 'Montserrat-ExtraBold',
    'Montserrat-ExtraLightItalic': 'Montserrat-ExtraLightItalic',
    'Montserrat-ExtraLight': 'Montserrat-ExtraLight',
    'Montserrat-Italic': 'Montserrat-Italic',
    'Montserrat-LightItalic': 'Montserrat-LightItalic',
    'Montserrat-Light': 'Montserrat-Light',
    'Montserrat-MediumItalic': 'Montserrat-MediumItalic',
    'Montserrat-Medium': 'Montserrat-Medium',
    'Montserrat-Regular': 'Montserrat-Regular',
    'Montserrat-SemiBoldItalic': 'Montserrat-SemiBoldItalic',
    'Montserrat-SemiBold': 'Montserrat-SemiBold',
    'Montserrat-ThinItalic': 'Montserrat-ThinItalic',
    'Montserrat-Thin': 'Montserrat-Thin',
  },
  color: {
    bgr: '#e5f5f8',
  },
};

export default theme;
