import styled from 'styled-components';
import IBgr from '@assets/images/footer-bgr.png';
import theme from '@styles/theme';
import { mixinsFlex } from '@styles/mixins';

export const SFooter = styled.footer`
  background-repeat: no-repeat;
  padding-bottom: 40px;
  background-size: auto 100%;
  background-position: left top;

  .contact-title,
  .resource-title {
    font-style: normal;
    font-size: 24px;
    margin: 0;
    padding: 0;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #fda800;
    text-shadow: 0px 0px 25px #fda800;
    text-transform: uppercase;
    font-family: ${theme.fonts['Montserrat-Bold']};
    margin-bottom: 24px;
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .footer-top {
    max-width: 750px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;

    .list-resource {
      .resource-item {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.05em;
        
        &:hover {
          a {
            color: #fda800;
            text-decoration: underline;
          }
        }
        a {
          color: white;
          text-decoration: none;
        }
      }
    }

    .contact {
      min-width: 400px;
      max-width: 100%;
      height: 213px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.12);
      padding: 32px 36px;
      box-sizing: border-box;

      ul.contact-social {
        display: flex;
        margin-bottom: 40px;

        li.social-item {
          margin: 4px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          left: 983.4px;
          top: 3240.99px;
          background: rgba(255, 255, 255, 0.12);
          /* logo */
          border: 1px solid #fda800;
          box-sizing: border-box;
          border-radius: 50%;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }

          svg {
            width: 17px;
            height: 17px;
            fill: #fda800;
          }
        }
      }

      .contact-title {
      }
      .contact-content {
        padding-left: 16px;
      }
    }
  }

  .footer-bottom {
    ${mixinsFlex};
    flex-direction: column;
    margin-top: 40px;
    text-align: center;
  }

  @media (max-width: 767px) {
    .footer-top {
      flex-direction: column;
      .contact {
        max-width: 100%;
        min-width: unset;
        padding: 12px;
        margin-top: 22px;
        height: unset;

        .contact-social {
          flex-wrap: wrap;
        }
      }
    }
  }
`;
