/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { SFooter } from './SFooter';
import { Container } from 'styled-bootstrap-grid';

import ILogoBN from '@assets/images/logo-bytenext.png';

export default function Footer() {
  return (
    <SFooter>
      <Container>
        <div className="footer">
          <div className="footer-bottom">
            <img src={ILogoBN} alt="logo ByteNext" className="logo-bytenext" />
            <div className="copyright">
              Copyright © 2021, ByteNext. All trademarks and copyrights belong
              to their respective owners.
            </div>
          </div>
        </div>
      </Container>
    </SFooter>
  );
}
