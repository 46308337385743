const rounding = (web3: any, value: number, lengthDecima = 6) => {
  if (web3 && value) {
    const res = web3.utils.fromWei(value);
    const divPart = res.toString().split('.');
    let result =
      divPart.length > 1
        ? divPart[0] + '.' + divPart[1].substring(0, lengthDecima)
        : divPart[0] + '.0';

    return parseFloat(result);
  } else return 0;
};

const formatCurrency = (value: number) => {
  if (value % 1 === 0)
    return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  return value.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export { rounding, formatCurrency };
