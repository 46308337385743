/* eslint-disable no-console */
import { Reducer } from 'redux';

import { rounding } from '@helpers/convert';
import { Alert } from '@components/Alert';

export const GET_INFO_USER = 'HOME/GET_INFO_USER';
export const SET_LIST_STAGE = 'HOME/SET_LIST_STAGE';
export const SET_LIST_PARTICIPANT = 'HOME/SET_LIST_PARTICIPANT';

export interface HomeStore {
  userBalance: number;
  userStaked: number;
  userInterest: number;
  userAllowance: number;
  lastStakingTime: number;
}

const initState: HomeStore = {
  userBalance: 0,
  userStaked: 0,
  userInterest: 0,
  userAllowance: 0,
  lastStakingTime: 0,
};

export const getBalance = () => {
  return (dispatch: any, getStore: any) => {
    const { userAddress, contractBNU, web3 } = getStore().App;
    if (contractBNU) {
      contractBNU.methods
        .balanceOf(userAddress)
        .call()
        .then((result: any) => {
          dispatch({
            type: GET_INFO_USER,
            userBalance: rounding(web3, result),
          });
        })
        .catch(() => console.log);
    }
  };
};

export const getUserStaked = () => {
  return (dispatch: any, getStore: any) => {
    const { userAddress, contractStake, web3 } = getStore().App;
    contractStake && contractStake.methods
      .getUserStakedAmount(userAddress)
      .call()
      .then((amount) => {
        const userStaked = rounding(web3, amount);
        dispatch({
          type: GET_INFO_USER,
          userStaked,
        });
      });
  };
};

export const userLastStakingTime = () => {
  return (dispatch: any, getStore: any) => {
    const { userAddress, contractStake, web3 } = getStore().App;
    contractStake && contractStake.methods
      .getUserLastStakingTime(userAddress)
      .call()
      .then((timestamp) => {
        dispatch({
          type: GET_INFO_USER,
          lastStakingTime: +timestamp,
        });
      });
  };
};

export const getInterest = () => {
  return (dispatch: any, getStore: any) => {
    const { userAddress, contractStake, web3 } = getStore().App;
    contractStake &&
      contractStake.methods
        .getUserEarnedAmount(userAddress)
        .call()
        .then((result: any) => {
          dispatch({
            type: GET_INFO_USER,
            userInterest: rounding(web3, result),
          });
        })
        .catch(() => console.log);
  };
}

export const getAllowance = () => {
  return (dispatch: any, getStore: any) => {
    const { userAddress, contractBNU, web3 } = getStore().App;
    contractBNU &&
      contractBNU.methods
        .allowance(userAddress, process.env.REACT_APP_ADDRESS_CONTRACT_STAKING)
        .call()
        .then((result: any) => {
          dispatch({
            type: GET_INFO_USER,
            userAllowance: rounding(web3, result),
          });
        })
        .catch(() => console.log);
  };
};

export const withdraw = (amount: number) => {
  return (dispatch: any, getStore: any) => {
    const { userAddress, contractStake, web3 } = getStore().App;
    if (contractStake) {
      dispatch({
        type: 'App/LOADING',
        payload: true,
      });
      const realAmount = web3.utils.toWei(amount.toString(), 'ether');
      contractStake.methods
        .withdraw(realAmount)
        .send({ from: userAddress })
        .then(() => {
          Alert({ icon: 'success', name: 'Withdraw susscess' });
          dispatch({
            type: 'App/LOADING',
            payload: false,
          });
          dispatch(getBalance());
          dispatch(getUserStaked());
          dispatch(getInterest());
        })
        .catch(() => {
          Alert({ icon: 'error', name: +realAmount === 0 ? 'Claim error!' : 'Withdraw error!' });
          dispatch({
            type: 'App/LOADING',
            payload: false,
          });
        });
    }
  };
};

export const clearData = () => {
  return (dispatch: any) => {
    dispatch({
      type: GET_INFO_USER,
      userBalance: 0,
      userStaked: 0,
      userInterest: 0,
      userAllowance: 0,
      lastStakingTime: 0
    });
  };
};


const reducersHome: Reducer<HomeStore> = (state = initState, actions) => {
  switch (actions.type) {
    case GET_INFO_USER:
      return { ...state, ...actions };
    default:
      return { ...state };
  }
};

export default reducersHome;
