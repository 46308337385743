import React, { useEffect, useState } from 'react';
import { SHeader, SModalAddress } from './SHeader';
import { Container } from 'styled-bootstrap-grid';
import BNUABI from '../../../contracts/BNU_ABI.json';
import STAKEABI from '../../../contracts/AvatarArtStaking_ABI.json';

import { setAddressUser, setEnvContract } from '@containers/App/store/reducers';
import { clearData } from '@containers/Home/store/reducers';

import { useDispatch } from 'react-redux';
import ILogo from '@assets/images/logo.png';
import ILogoBN from '@assets/images/logo-bytenext.png';

import { Link, useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectAppStore } from '@containers/App/store/selecters';
import { selectHomeStore } from '@containers/Home/store/selecters';
import Modal from '@components/Modal';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import useMediaQuery from '@hooks/useMediaQuery';
import IClose from '@assets/images/close.png';
import IMenu from '@assets/images/menu.png';
import usePosition from '@hooks/usePosition';

declare const window: any;

export default function Header() {
  const { userAddress, clearCache } = useSelector(selectAppStore);
  const [showWallet, setShowWallet] = useState<boolean>(false);

  const dispatch = useDispatch();

  const setConnect = async () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            1: process.env.REACT_APP_RPC,
            [process.env.REACT_APP_NETID || '']: process.env.REACT_APP_RPC,
          },
          chainId: process.env.REACT_APP_NETID,
          bridge: 'https://pancakeswap.bridge.walletconnect.org',
        },
      },
    };
    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });
    const clearCache: any = () => web3Modal.clearCachedProvider();
    let web3: any = null;
    try {
      const provider = await web3Modal.connect();
      provider.on('disconnect', () => logOut());
      web3 = new Web3(provider);
    } catch (error) {
      const provider = new Web3.providers.HttpProvider(
        process.env.REACT_APP_RPC || '',
      );
      web3 = new Web3(provider);
    }
    setRedux(web3, clearCache);
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async function () {
        const accounts = await web3.eth.getAccounts();
        accounts.length && dispatch(setAddressUser(accounts[0]));
      });
      window.ethereum.on('chainChanged', async (netId: number) => {
        if (netId.toString() !== process.env.REACT_APP_NETID) switchNetWork();
        setRedux(web3);
      });
    }
    if (web3.eth && window.ethereum) {
      const netId = await web3.eth.net.getId();
      if (netId.toString() !== process.env.REACT_APP_NETID) switchNetWork();
    }
  };
  const setRedux = async (web3: any, clearCache = null) => {
    const accounts = await web3.eth.getAccounts();
    const userAcc = accounts.length ? accounts[0] : null;
    dispatch(setAddressUser(userAcc));
    const ctToken = new web3.eth.Contract(
      BNUABI,
      process.env.REACT_APP_ADDRESS_CONTRACT_BNU,
    );
    const ctAvt = new web3.eth.Contract(
      STAKEABI,
      process.env.REACT_APP_ADDRESS_CONTRACT_STAKING,
    );
    dispatch(
      setEnvContract({
        web3: web3,
        contractBNU: ctToken,
        contractStake: ctAvt,
        clearCache,
      }),
    );
  };

  const switchNetWork = async () => {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${parseInt(process.env.REACT_APP_NETID || '').toString(
            16,
          )}`,
          chainName: process.env.REACT_APP_NAME_NET,
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: [process.env.REACT_APP_RPC],
          blockExplorerUrls: ['https://bscscan.com'],
        },
      ],
    });
  };

  const { width } = useMediaQuery();
  const [menuMobile, setmenuMobile] = useState(false);

  useEffect(() => {
    if (menuMobile) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [menuMobile]);

  const history = useHistory();
  history.listen(() => {
    setmenuMobile(false);
  });
  
  const logOut = () => {
    setShowWallet(false);
    clearCache && clearCache();
    const provider = new Web3.providers.HttpProvider(
      process.env.REACT_APP_RPC || '',
    );
    const web3 = new Web3(provider);
    setRedux(web3);
    dispatch(clearData());
    localStorage.clear();
  };

  const { y } = usePosition();
  return (
    <SHeader className={`${y > 100 ? 'scroll' : ''}`}>
      <Container>
        <div className="header">
          <Link to="/" className="logo">
            <img src={ILogoBN} alt="logo ByteNext" className="logo-bytenext" />
          </Link>
          <div className="nav">
            {width < 1200 ? (
              <div className={`nav-list`}>
                <span
                  onClick={() => {
                    !userAddress ? setConnect() : setShowWallet(!showWallet);
                  }}
                  className="user-address"
                >
                  {userAddress
                    ? `${userAddress.slice(0, 5)}...${userAddress.slice(
                        userAddress.length - 5,
                        userAddress.length,
                      )}`
                    : 'Connect'}
                </span>
                <span
                  className="open-menu"
                  onClick={() => {
                    setmenuMobile(!menuMobile);
                  }}
                >
                  <img src={IMenu} alt="menu" />
                </span>{' '}
              </div>
            ) : (
              ''
            )}

            {width > 1199 ? (
              <div className={`nav-list`}>
                <span
                  onClick={() => {
                    !userAddress ? setConnect() : setShowWallet(!showWallet);
                  }}
                  className="user-address"
                >
                  {userAddress
                    ? `${userAddress.slice(0, 5)}...${userAddress.slice(
                        userAddress.length - 5,
                        userAddress.length,
                      )}`
                    : 'Connect'}
                </span>
              </div>
            ) : (
              <div className={`nav-list-mobile ${menuMobile ? 'active' : ''}`}>
                <span
                  onClick={() => {
                    setmenuMobile(!menuMobile);
                  }}
                  className="close-icon"
                >
                  <img src={IClose} alt="close" />
                </span>
              </div>
            )}
          </div>
        </div>
      </Container>
      {/* modal show wallet */}
      <Modal
        open={showWallet}
        onClose={() => {
          setShowWallet(false);
        }}
        center
      >
        <SModalAddress>
          <h2>Your wallet address</h2>
          <p>{userAddress}</p>
          <button className="btn-logout" onClick={() => logOut()}>
            Logout
          </button>
        </SModalAddress>
      </Modal>
    </SHeader>
  );
}
