import React from 'react';
import { GlobalStyle } from '@styles/global-styles';
import { ThemeProvider } from 'styled-components';
import { Web3Provider } from './Web3Provider';
// css style configs
import theme from '@styles/theme';
import 'normalize.css';
import '@styles/fonts.css';
import { BaseCSS } from 'styled-bootstrap-grid';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-responsive-modal/styles.css';

// diff import
import Loading from '@components/Loading';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NotFoundPage from '@components/NotFoundPage';
import { selectAppStore } from './store/selecters';
import LanguageProvider from './LanguageProvider';
// import useCountRenders from '@hooks/useCountRenders';
import Layout from './Layout';

// load page
import loadable from '@components/LazyLoad';
const Home = loadable(() => import('@containers/Home'));

function App() {
  const { loading } = useSelector(selectAppStore);

  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Web3Provider>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Web3Provider>
          </Layout>
        </Router>
        <Loading active={loading} />
        <GlobalStyle />
        <BaseCSS />
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
