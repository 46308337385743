/*
 *
 * App reducers
 *
 */
import { Reducer } from 'redux';
import * as types from './constants';
import { ActionsApp, AppStore } from './types';
import { AppLanguage } from './types';

const initState: AppStore = {
  lang: AppLanguage['en'],
  loading: false,
  userAddress: '',
  web3: null,
  contractBNU: null,
  contractStake: null,
  clearCache: null,
};

export const setAddressUser = (address: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_ADDRESS_USER, userAddress: address });
  };
};

export const setEnvContract = (obj: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_ENV_WEB3, payload: obj });
  };
};

const reducersApp: Reducer<AppStore, ActionsApp> = (
  state = initState,
  actions: ActionsApp,
) => {
  switch (actions.type) {
    case types.LOADING: {
      return { ...state, loading: actions.payload };
    }

    case types.LANGUAGE: {
      return { ...state, lang: actions.payload };
    }
    case types.SET_ADDRESS_USER:
      return { ...state, ...actions };
    case types.SET_ENV_WEB3:
      return { ...state, ...actions.payload };
    default:
      return { ...state };
  }
};

export default reducersApp;
