/*
 *
 * App constants
 *
 */

export const LANGUAGE = 'App/LANGUAGE';
export const LOADING = 'App/LOADING';
export const SET_ADDRESS_USER = 'App/SET_ADDRESS_USER';

export const SET_ENV_WEB3 = 'App/SET_ENV_WEB3';


