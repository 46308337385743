import styled from 'styled-components';
import { mixinsFlex } from '@styles/mixins';

export const SHeader = styled.header`
  /* background-color: rgba(44, 19, 1, 1); */
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: all 0.3s ease;
  background: rgba(93, 48, 0, 0.25);

  &.scroll {
    .header {
      height: 60px;
    }
    background-color: rgb(183 170 170 / 25%);
  }

  .open-menu {
    cursor: pointer;
  }

  .header {
    ${mixinsFlex};
    height: 80px;

    .logo {
      img {
        height: 70px;
      }
    }
    .nav {
      ${mixinsFlex};

      .nav-list,
      .nav-list-mobile {
        ${mixinsFlex};

        .user-address {
          cursor: pointer;
          font-weight: bold;
          height: 40px;
          background: #5d3000;
          border-radius: 40px;
          color: #fff;
          text-decoration: unset;
          display: flex;
          align-items: center;
          padding: 0 12px;
          margin-right: 12px;
          &.buy {
            border: 1px solid #fda800;
            background: #fda800;
          }
        }
        .totalStaked {
          color: #fda800;
        }
        .nav-item {
          margin-right: 24px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          transition: all 0.3s ease;

          &:hover {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #fda800;
            text-shadow: 0px 0px 25px #fda800;
          }
        }
      }
      .user {
        ${mixinsFlex};
        width: 80px;
        height: 40px;
        background: #5d3000;
        border-radius: 40px;

        padding-right: 12px;
        box-sizing: border-box;
      }
      .user-address.user-address__link {
        background: unset;
        a {
          color: white;
          text-decoration: none;
        }
        &:hover a {
          text-decoration: underline;
          color: #fda800;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .header .nav .nav-list-mobile {
      position: fixed;
      left: -200%;
      top: 0;
      flex-direction: column;
      align-items: start;
      z-index: 1;
      background: rgb(43, 37, 37, 95%);
      width: 100vw;
      height: 100vh;
      justify-content: start;
      overflow: hidden;
      padding: 12px;
      padding-top: 50px;
      align-items: flex-start;
      text-align: left;
      color: white !important;
      justify-content: stretch;
      transition: all 0.3s ease;

      &.active {
        left: 0;
      }

      .close-icon {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 20px;
        padding: 10px;
      }
      .user-address {
        margin-bottom: 12px;
      }
      .user-address__mobile {
        display: flex;
        height: unset;
        flex-direction: column;
        background: none !important;
        justify-content: stretch;
        align-items: flex-start !important;
        border-bottom: 1px solid #5d5757;
        border-radius: 0 !important;
        width: 100%;
        padding: 12px 0;
        margin-bottom: 12px;
        color: #fff;

        p {
          margin: 0;
          margin-bottom: 12px;
          word-break: break-all;
          padding-right: 15px;
        }
      }
      .user-address.user-address__link {
        background: unset;
        &:hover {
          text-decoration: underline;
          color: #fda800;
        }
      }
    }
  }
`;

export const SModalAddress = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #fda800;
  text-align: center;
  .btn-logout {
    border: 1px solid #fda800;
    background: #fda800;
    cursor: pointer;
    font-weight: bold;
    padding: 4px 9px;
    border-radius: 10px;
    color: #fff;
  }
`;
