/**
 *
 * Modal
 * make by phamthainb
 */
import ModalLib from 'react-responsive-modal';
import { ModalProps } from 'react-responsive-modal';
import React from 'react';
import IClose from '@assets/images/close.png';

interface Props extends ModalProps {}

const closeIcon = <img src={IClose} alt="close modal" />;

const Modal = (props: Props) => {
  return (
    <ModalLib
      classNames={{
        overlay: 'react-responsive-modal__customOverlay',
        modal: 'react-responsive-modal__customModal',
      }}
      closeIcon={closeIcon}
      {...props}
    />
  );
};

export default Modal;
