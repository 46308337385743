import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`  
  body{
    padding : 0;
    margin : 0;
    font-family: ${theme.fonts['Montserrat-Regular']};
    color: white;
    background-color: rgba(44, 19, 1, 1);
  }

  /// for modal
  .react-responsive-modal__customOverlay{background: rgba(0,0,0, 0.6);}
  .react-responsive-modal__customModal{
    background: #211100;
    border: 1px solid #926A00;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 100%;
    margin: 0;
  }

  // img
  img{
    max-width: 100%;
  }
`;
